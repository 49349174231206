import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import ConfigRouter from "@/common/Config";
import PosSimpleRoute from "@/apps/PosSimple";
import ERPRouter from "@/apps/ERP";
import AlmacenRouter from "@/apps/Almacen";
import TareasRouter from "@/apps/Tareas";
import TareasAvanzadasRouter from "@/apps/TareasAvanzadas";
import CMMSRouter from "@/apps/CMMS";
import CfdiPdfRouter from "@/apps/CfdiPdf";
import FilePokerRouter from "@/apps/FilePoker";
import PCAssetsRouter from "@/apps/PCAssets";
import TimingRouter from "@/apps/Timing";
import FormsRouter from "@/apps/Forms";
import HerstellerRouter from "@/apps/Hersteller";
import TemplateRouter from "@/apps/Template";
import RecepcionGastosRouter from "@/apps/RecepcionGastos";
import BlogRouter from "@/apps/Blog";
import DescargaCfdiRouter from "@/apps/DescargaCfdi";
import ImpuestosRouter from "@/apps/Impuestos";
import MallainsaRouter from "@/apps/Mallainsa";
import EncorpServiceRouter from "@/apps/ENCORPservice";
import CadenaCustodiaRouter from "@/apps/CadenaCustodia";
import RhRouter from "@/apps/RH";
import LocomotiveRouter from "@/apps/Locomotive";
import FacturacionMasivaRouter from "@/apps/FacturacionMasiva";
import ERP3Router from "@/apps/ERP3";

const systemRoutes = [
  {
    path: "",
    component: () => import("@/common/Router.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("@/common/Dashboard/Dashboard"),
  },
  {
    path: "/menuservicios",
    component: () => import("@/common/Dashboard/MenuServicios"),
  },
  {
    path: "/login",
    component: () => import("@/common/Login/Login"),
  },
  {
    path: "/login/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/servicios/:empresastr",
    component: () => import("@/common/Login/LoginServicios"),
    props: true,
  },
  {
    path: "/facturacion",
    component: () => import("@/common/FacturacionHome"),
  },
  {
    path: "/facturacion/:empresastr",
    component: () => import("@/common/FacturacionHome"),
    props: true,
  },
  {
    path: "/e/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/pdf/:url",
    component: () => import("@/common/Visualizadores/PdfIframe"),
    props: true,
  },
  {
    path: "/*",
    component: () => import("@/common/404"),
  },
];

const appRoutes = [
  ConfigRouter,
  PosSimpleRoute,
  ERPRouter,
  MallainsaRouter,
  AlmacenRouter,
  TareasRouter,
  TareasAvanzadasRouter,
  CMMSRouter,
  CfdiPdfRouter,
  FilePokerRouter,
  PCAssetsRouter,
  TimingRouter,
  FormsRouter,
  HerstellerRouter,
  RecepcionGastosRouter,
  BlogRouter,
  TemplateRouter,
  DescargaCfdiRouter,
  EncorpServiceRouter,
  CadenaCustodiaRouter,
  ImpuestosRouter,
  RhRouter,
  LocomotiveRouter,
  FacturacionMasivaRouter,
  ERP3Router,
];
const routes = [
  ...systemRoutes,
  ...appRoutes.reduce((list, router) => {
    list = [...list, ...router.router];
    return list;
  }, []),
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
