let rutaLocal = "@/apps/FacturacionMasiva/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/facturacionmasiva",
    redirect: "/facturacionmasiva/lista",
  },
  {
    path: "/facturacionmasiva/lista",
    name: "FacturacionMasivaLista",
    component: () =>
      import(
        /* webpackChunkName: "facturacionmasivalista" */ rutaLocal + "Lista"
      ),
  },
  {
    path: "/facturacionmasiva/clientes",
    name: "FacturacionMasivaClientesLista",
    component: () =>
      import(
        /* webpackChunkName: "facturacionmasivaclientelista" */ rutaLocal +
          "Clientes"
      ),
  },

  {
    path: "/facturacionmasiva/clientes/:id",
    name: "FacturacionMasivaClienteHandler",
    component: () =>
      import(
        /* webpackChunkName: "facturacionmasivaclientehandler" */ rutaLocal +
          "Cliente"
      ),
    props: true,
  },
  {
    path: "/facturacionmasiva/productos",
    name: "FacturacionMasivaProductosLista",
    component: () =>
      import(
        /* webpackChunkName: "facturacionmasivaproductoslista" */ rutaLocal +
          "Productos"
      ),
  },
  {
    path: "/facturacionmasiva/pagos",
    name: "FacturacionMasivaComplementosPago",
    component: () =>
      import(
        /* webpackChunkName: "facturacionmasivacomplementospago" */ rutaLocal +
          "Pagos"
      ),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Facturación Masiva",
  // descripcion aparece en el menú
  descripcion: "Facturación desde una hoja de cálculo",
  tarifa: 0,
  // Ícono principal
  icono: "codeWorking",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear facturas",
    "Puede cancelar facturas",
    "Puede crear clientes",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/facturacionmasiva",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/facturacionmasiva",
      label: "Facturación masiva",
      permiso: "Puede crear facturas",
      icono: "codeWorking",
    },
    {
      ruta: "/facturacionmasiva/pagos",
      label: "Complementos de pago",
      permiso: "Puede crear facturas",
      icono: "cash",
    },
    {
      ruta: "/erp/pos",
      label: "Facturación individual",
      permiso: "Puede crear facturas",
      icono: "codeWorking",
    },
    {
      ruta: "/facturacionmasiva/clientes",
      label: "Catálogo de clientes",
      permiso: "Puede crear clientes",
      icono: "people",
    },
    {
      ruta: "/facturacionmasiva/productos",
      label: "Catálogo de productos",
      permiso: "Puede crear productos",
      icono: "pricetag",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  variables: [
    {
      label: "Nombre fiscal",
      name: "nombreFiscal",
      transform: `(str) => str.toUpperCase().trim()`,
    },
    {
      label: "RFC",
      name: "rfc",
      minlength: 12,
      maxlength: 14,
      transform: `(str) => str.replace(/ /g, "").toUpperCase()`,
    },
    {
      label: "Régimen fiscal",
      name: "regimenFiscal",
      transform: `(str) => str.toUpperCase().trim()`,
    },
    {
      label: "Certificado CSD",
      name: "uploadcer",
      b64: true,
      omitirHash: false,
      accept: ".cer",
    },
    {
      label: "Llave privada CSD",
      name: "uploadkey",
      b64: true,
      omitirHash: false,
      accept: ".key",
    },
    {
      label: "Timbrar en modo producción (timbres reales)",
      name: "timbrarProduccion",
      type: "binary",
    },
    {
      label: "PAC Usuario",
      name: "FacturamaUsuario",
    },
    {
      label: "PAC Password",
      name: "FacturamaPassword",
      type: "password",
    },
    {
      label: "Contraseña el certificado SAT",
      name: "satPassword",
      type: "password",
    },
    {
      label: "Email para enviar copia de facturas",
      name: "emailCopiaFacturacion",
      type: "email",
    },
    {
      label: "Serie de facturación",
      name: "FacturamaSerie",
      type: "text",
    },
    {
      label: "Código postal de emisión",
      name: "codigoPostal",
      type: "text",
    },
    {
      label: "Guardar PDFs en el servidor",
      name: "guardarPdfEnServidor",
      type: "binary",
    },
  ],
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
