/*

Ittoni APP
Alberto Herrera Olvera
Abril de 2022

- Para importar módulos, ir a /router
- Ser minimalista! Ya hay suficiente boilerplate al importar desde ionicons y @ionic/vue
- Cada miniapp debe portar sus propios modelos de ser posible. No abusar de /common
- Sonríe, chingada madre

*/

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

const producto = "hum";
const determinarTema = () => {
  //const appEsElectron = () => !!window && window.process && window.process.type;
  //if (appEsElectron) return "electron";
  if (producto != "") return producto;
  else if (window.location.href.indexOf("ittoni") >= 0) return "ittoni";
  else if (window.location.href.indexOf("encorp") >= 0) return "encorp";
};

let tema = determinarTema();
//let tema = "ittoni";
import("./theme/" + tema + ".variables.css");
localStorage.setItem("tema", tema);

//import "./registerServiceWorker";

const app = createApp(App).use(IonicVue).use(router);

// Apagar el Console.log
if (window.location.href.indexOf("localhost") <= 0) console.log = () => {};

router.isReady().then(() => {
  app.mount("#app");
});
