<script>
import { format } from "date-fns";
import { es } from "date-fns/locale";
import colores from "@/common/colores.json";
import sha1 from "sha1";
const defaultWaitTimeout = 1000;
let contadorColor = 0;
const HelperFunctions = {
  defaultWaitTimeout,
  randomColor: (trueRandom = false) => {
    let indice = trueRandom
      ? Math.floor(Math.random() * colores.length)
      : contadorColor;
    contadorColor++;
    if (contadorColor > colores.length - 1) contadorColor = 0;
    // console.log("randomcolor?", trueRandom, indice, colores[indice]);
    return colores[indice];
  },
  seedRandomColor: () => {
    contadorColor = Math.floor(Math.random() * 10);
  },
  errorHandler: function (e) {
    return e; // TODO Para que sirve esto despues?
  },
  moment: (a = new Date(), b = "yyyy-MM-dd") => {
    if (typeof a == "string") a = new Date(a);
    return format(a, b, { locale: es });
  },
  currency2: (a = 0, b = 2) => parseFloat(a).toFixed(b),
  currency: (a = 0, decimals = 2) => {
    const formatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return formatter.format(a).replace(/\$/g, "");
  },
  roundNumber: (num = 0) => Math.round(parseFloat(num) * 100) / 100,
  limpiarObjetos: (objeto = "") => {
    const propsNoTraducibles = ["telefono", "celular", "email"];
    const propsParseablesAFloat = ["costo", "cantidad", "precio"];
    try {
      for (const prop in objeto) {
        let noEsTraducible = false;
        let debeSerParseadoAFloat = false;
        let esBooleano = ["true", "false"].indexOf(objeto[prop]) >= 0;
        const esFechaTraducible =
          prop.startsWith("fecha") && !isNaN(objeto[prop]);
        switch (typeof objeto[prop]) {
          case "string":
            for (const nt of propsNoTraducibles)
              if (prop.startsWith(nt)) noEsTraducible = true;
            for (const nt of propsParseablesAFloat)
              if (prop.startsWith(nt)) debeSerParseadoAFloat = true;
            if (esFechaTraducible) objeto[prop] = parseInt(objeto[prop]);
            else if (noEsTraducible) continue;
            else if (esBooleano) {
              objeto[prop] = objeto[prop] == "true";
            } else if (debeSerParseadoAFloat)
              try {
                objeto[prop] = parseFloat(objeto[prop]);
              } catch (e) {
                console.error("EPARSEFLOAT", prop, objeto[prop]);
              }
            // console.log("U?", objeto[prop], { prop, noEsTraducible, debeSerParseadoAFloat, esFechaTraducible,esBooleano });
            break;
          case "object":
            if (Array.isArray(objeto[prop]))
              objeto[prop] = [
                ...objeto[prop].map(HelperFunctions.limpiarObjetos),
              ];
            else objeto[prop] = HelperFunctions.limpiarObjetos(objeto[prop]);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      console.error(",,,,,", e, objeto);
    }
    return objeto;
  },
  limpiarCadena: (str = "") =>
    str
      .replace(/\&#xA;/g, "\n")
      .replace(/%20/g, " ")
      .replace(/Ã¡/g, "á")
      .replace(/É/g, "é")
      .replace(/Ã©/g, "é")
      .replace(/Ã­/g, "í")
      .replace(/Ã³/g, "ó")
      .replace(/%2C/g, ",")
      .replace(/%24/g, "\$")
      .replace(/%28/g, "(")
      .replace(/%29/g, ")")
      .replace(/Ã±/g, "ñ"),
  esMovil: () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const tresholdSize = 1000;
    return w < h || (w < tresholdSize && h < tresholdSize);
  },
  esMovilOld: function () {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  permiso: function (persona = {}, str = "") {
    let p = false;
    for (let modulo in persona.permisos || {})
      if (
        persona.permisos &&
        persona.permisos[modulo] &&
        persona.permisos[modulo][str] &&
        persona.permisos[modulo][str] == true
      )
        p = true;
    if (!p && persona.rol == "administrador") p = true;
    return p;
  },
  // Convertir imagen a WEBP. Gracias, ChatGPT
  convertImageToWebP: (inputId, maxSize = 1000, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      const inputElement = document.getElementById(inputId);
      const file = inputElement.files[0];

      // Check if a file is selected
      if (!file) {
        reject(new Error("No file selected"));
        return;
      }

      // Check if the selected file is an image
      if (!file.type.startsWith("image/")) {
        reject(new Error("Selected file is not an image"));
        return;
      }

      const reader = new FileReader();

      reader.onload = function () {
        const image = new Image();

        image.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let width = image.width;
          let height = image.height;

          // Scale the image if it exceeds the maximum size
          if (width > maxSize || height > maxSize) {
            console.log("Escalando imagen", { maxSize, height, width });
            window.toast("Escalando. Maximo: " + maxSize + "x" + maxSize);
            const aspectRatio = width / height;
            if (width > height) {
              width = maxSize;
              height = width / aspectRatio;
            } else {
              height = maxSize;
              width = height * aspectRatio;
            }
          }
          canvas.width = width;
          canvas.height = height;
          // Draw the scaled image on the canvas
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const reader = new FileReader();

              reader.onloadend = function () {
                const base64String = reader.result.split(",")[1];
                resolve(`data:image/webp;base64,${base64String}`);
              };

              reader.onerror = function () {
                reject(new Error("Failed to convert image to base64"));
              };

              reader.readAsDataURL(blob);
            },
            "image/webp",
            quality
          );
        };

        image.onerror = function () {
          reject(new Error("Failed to load image"));
        };

        image.src = reader.result;
      };

      reader.onerror = function () {
        reject(new Error("Failed to read image file"));
      };

      reader.readAsDataURL(file);
    });
  },
  capitalize: (inputString = "") => {
    // Split the input string into words
    const words = inputString.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      // Ensure the word is not empty
      if (word.length === 0) return "";

      // Capitalize the first letter and make the rest of the letters lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the capitalized words back into a string
    const capitalizedString = capitalizedWords.join(" ");

    return capitalizedString;
  },
  waitForElement: (selector, waitTime = 100, loops = 100) => {
    // Esperar asyncronamente a que un elemento exista en DOM
    const qS = window.document.querySelector(selector);
    if (qS) {
      console.log("Elemento existe!", selector);
      return Promise.resolve(qS);
    } else if (loops > 0) {
      loops--;
      setTimeout(() => {
        HelperFunctions.waitForElement(selector, waitTime, loops);
      }, waitTime);
    } else return Promise.reject(true);
  },
  tokenHash: function () {
    let tiempo = new Date().toISOString().substring(0, 13);
    let hash = sha1(tiempo).substring(0, 6);
    if (!this.modoProduccion) console.log("tokenHash?", tiempo, hash);
    return hash;
  },
  esImagen: function (str = "") {
    let imagen = false;
    const url = (str ?? "").toLowerCase();
    for (const ext of ["bmp", "png", "jpg", "jpeg", "webp", "svg"])
      if (url.indexOf(`.${ext}`) >= 0) imagen = true;
    return imagen;
  },

  // Tailwind baby
  findNearestTailwindColor: (hexColor = "#000000") => {
    // Function to convert hex to RGB
    const hexToRgb = (hex) => {
      // Remove '#' symbol if present
      hex = hex.replace(/^#/, "");

      // Convert hex to RGB
      return {
        r: parseInt(hex.substring(0, 2), 16),
        g: parseInt(hex.substring(2, 4), 16),
        b: parseInt(hex.substring(4, 6), 16),
      };
    };

    // Function to calculate color difference using Euclidean distance
    const colorDifference = (color1, color2) => {
      const rDiff = color1.r - color2.r;
      const gDiff = color1.g - color2.g;
      const bDiff = color1.b - color2.b;

      return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
    };

    // Convert input hex color to RGB
    const inputRgbColor = hexToRgb(hexColor);

    let closestColor = null;
    let minDifference = Infinity;

    let tailwind = window.tailwind;

    // Iterate through tailwind.colors object to find the closest color
    for (const color in tailwind.colors) {
      if (Object.prototype.hasOwnProperty.call(tailwind.colors, color)) {
        // Iterate through shades of the color
        for (const shade in tailwind.colors[color]) {
          if (
            Object.prototype.hasOwnProperty.call(tailwind.colors[color], shade)
          ) {
            // Get RGB values of the current Tailwind color shade
            const tailwindRgbColor = hexToRgb(tailwind.colors[color][shade]);

            // Calculate difference between input color and current Tailwind color shade
            const difference = colorDifference(inputRgbColor, tailwindRgbColor);

            // Update closest color if a closer match is found
            if (difference < minDifference) {
              minDifference = difference;
              closestColor = `${color}-${shade}`;
            }
          }
        }
      }
    }

    return closestColor;
  },
};
export default HelperFunctions;
</script>
